import React from "react";
import profileImage from "../Assets/Images/pr.jpeg";

const About = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start p-6 bg-white rounded-lg  max-w-4xl mx-auto mb-5">
      <div className="w-full md:w-1/3">
        <img
          src={profileImage}
          alt="Profile"
          className="rounded-lg shadow-md"
        />
      </div>

      <div className="w-full md:w-2/3 md:ml-8 mt-6 md:mt-4 text-center md:text-left">
        <h1 className="text-4xl font-bold text-gray-900">Anvi Kalidindi</h1>
        <div className="text-blue-500 mt-5">
          <a href="/resume.pdf" className="mr-4">
            Resume
          </a>
          <a href="https://github.com/anviverma-code" className="mr-4">
            GitHub
          </a>
          <a
            href="https://www.linkedin.com/in/anvi-kalidindi-a93393256"
            className="mr-4"
          >
            LinkedIn
          </a>
          <a href="mailto:akpixie867@gmail.com">Email</a>
        </div>
        <p className="text-gray-700 mt-4 leading-[46px]">
          I have graduated from Jesuit High School, Class of 2025. Advanced
          Coursework: AP Calc AB, AP Comp Sci, AP Cale BC, Linear Algebra, AP
          Lang, AP Physics C.
        </p>
      </div>
    </div>
  );
};

export default About;
